<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario},{'font-size': '20px'}]"><b>RESPUESTA CASOS DEFENSOR DEL CONSUMIDOR</b></span></v-toolbar>
      <v-card-text class="pt-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-autocomplete v-model="form_consumidor.aceptacion_id" clearable :items="items.items_aceptaciones" item-text="descripcion" item-value="id" label="ACEPTACIÓN"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-autocomplete v-model="form_consumidor.rectificacion_id" clearable :items="items.items_rectificaciones" item-text="descripcion" item-value="id" label="RECTIFICACIÓN"></v-autocomplete>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
  <br>
</div>
</template>

<script>

export default {
  name: 'form_qr_cierre_consumidorComponent',
  components: {
  },
  props: ['colores', 'rules', 'items', 'form_consumidor'],
  data: () => ({

  }),
  methods: {

  }
}
</script>
